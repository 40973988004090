import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import CartProduct from "./CartProduct";
import CartMessage from "./CartMessage";
import { CartContext } from "../context/CartContext";
import { EventContext } from "../context/EventContext";
import { CLOSE_CART } from "../reducers/CartReducer";

const openCart = "active";
const fadeOut = "fadeOut";

const CartPopup = ({ headerRef, addLogo }) => {
  const { cartState, cartDispatch } = useContext(CartContext);
  const { eventID } = useContext(EventContext);
  const overlay = document.getElementsByClassName('darkOverlay')[0];
  const outerDiv = useRef();
  const container = document.getElementsByClassName("cartUI")[0];

  // Fade out the cart
  useEffect(() => {
    if (!container) return;
    container.classList.add(fadeOut);
    return () => container.classList.remove(fadeOut);
  }, [container]);

  const handleClick = useCallback((e) => {
    if (!outerDiv.current.contains(e.target) && !headerRef.current.contains(e.target)) {
      cartDispatch({type: CLOSE_CART});
    }
  }, [cartDispatch, headerRef]);

  // Close the cart when the user clicks outside of it
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, [handleClick]);

  useEffect(() => {
    overlay.classList.add(openCart);
    return () => overlay.classList.remove(openCart);
  }, [overlay.classList]);

  const showLastProduct = useCallback(() => {
    if (!cartState) return null;
    const cartProduct = cartState.newestProduct;
    return (
      <div>
        <CartProduct 
          key={cartProduct.id} 
          id={cartProduct.id} 
          productID={cartProduct.product.xID} 
          size={cartProduct.size} 
          quantity={cartProduct.quantity + 1} 
          addLogo={addLogo}
        />
      </div>
    )
  }, [cartState, addLogo]);

  return (
    <div ref={outerDiv}>
      <div className="cartUI">
        {showLastProduct()}
        <CartMessage/>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Link
            to={`/${eventID}/store`} className="buttonSecondary"
            onClick={() => cartDispatch({ type: CLOSE_CART })}
            style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center", marginRight: "10px"}}>
            <span>Back to Shop</span>
          </Link>
          <Link
            to={`/${eventID}/cart`} className="button"
            onClick={() => cartDispatch({ type: CLOSE_CART })}
            style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
            <span>View Cart</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartPopup;
