import React, {
	useState,
	useContext,
	useMemo,
	useEffect,
	useCallback,
	useRef,
} from "react";
import { Link } from "react-router-dom";
import { REMOVE_PRODUCT, SET_CART_PRODUCT } from "../reducers/CartReducer";
import MonogramConfig from "./MonogramConfig";
import { EventContext } from "../context/EventContext";
import { CartContext } from "../context/CartContext";
import { getAvailability, getSizeScale } from "../firestore";
import {
	asyncFilter,
	getBitmap,
	getNzForSizeInSizeScale,
	getPriceString,
	getEncodedProductStyle,
	getEncodedProductColor,
	capitalizeFirstLetter,
} from "../helpers";
import { RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from "@material-ui/core";
import { CLIENT, GTN } from "../constants";

const Monogram = ({ monogram }) => {
	return (
		<div className="tapeImage" style={{ display: "flex" }}>
			<div className="monogramLabel">
				<div>Top text:</div>
				{monogram?.bottomText && <div>Bottom text:</div>}
				<div>Text style:</div>
			</div>
			<div className="monogram">
				{monogram?.topText ? (
					<div>{monogram?.topText}</div>
				) : (
					<div className={"invalid"}>NONE</div>
				)}
				{monogram?.bottomText && <div>{monogram?.bottomText}</div>}
				{monogram?.style ? (
					<div>{monogram?.style}</div>
				) : (
					<div className={"invalid"}>NONE</div>
				)}
			</div>
		</div>
	);
};

const EmbroideryTape = ({ tape }) => {
	const tapeImage = getBitmap(tape);
	return (
		<div className="tapeImage">
			<img alt="" src={tapeImage} />
		</div>
	);
};

const EmbroideryOption = ({
	option,
	selectedEmbroidery,
	setSelectedEmbroidery,
	monogram,
}) => {
	const { isMonogram } = useContext(EventContext);
	const [selected, setSelected] = useState(
		!!selectedEmbroidery?.some(
			(emb) =>
				JSON.stringify({ tape: emb.tape, position: emb.position }) ===
				JSON.stringify({ tape: option.tape, position: option.position })
		)
	);

	const monogramOption = useMemo(
		() => isMonogram(option.tape),
		[isMonogram, option.tape]
	);

	const isFirstRun = useRef(true);

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		if (selected) {
			setSelectedEmbroidery((selectedEmbroidery) =>
				selectedEmbroidery ? [...selectedEmbroidery, option] : [option]
			);
		} else {
			setSelectedEmbroidery((selectedEmbroidery) =>
				selectedEmbroidery?.filter(
					(emb) =>
						JSON.stringify({ tape: emb.tape, position: emb.position }) !==
						JSON.stringify({ tape: option.tape, position: option.position })
				)
			);
		}
	}, [selected, setSelectedEmbroidery, option]);

	if (!option?.tape || !option?.position?.name || !option?.position?.code) {
		return;
	}
	const name = capitalizeFirstLetter(option.position?.name);
	const logoType = monogramOption ? "Monogram" : "Logo";

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				marginBottom: 8,
				marginTop: 15,
			}}
			id="embroidery"
		>
			<FormControl>
				<FormLabel id="embroidery-group-label">{name}</FormLabel>
					<RadioGroup
						value={selected.toString()}
						onChange={() => setSelected(!selected)}
						>
						<FormControlLabel
							value="true"
							control={<Radio />}
							label={logoType}
							style={{ cursor: "pointer" }}
						/>
						
						<FormControlLabel
							value="false"
							control={<Radio />}
							label={`No ${logoType}`}
							style={{ cursor: "pointer" }}
						/>
						</RadioGroup>
					</FormControl>
				<div
					style={{ marginLeft: 10 }}
				>
					{monogramOption ? (
						selected && <Monogram monogram={monogram} />
					) : (
						<EmbroideryTape tape={option.tape} />
					)}
				</div>
		</div>
	);
};

const CheckoutProduct = ({ id, productID, size, quantity, embroidery, addLogo }) => {
	const {
		eventID,
		products,
		productList,
		customUnits,
		lastLogoDate,
		lastOrderDate,
		isMonogram,
	} = useContext(EventContext);
	const { cartState, cartDispatch } = useContext(CartContext);

	const thisCartProduct = useMemo(() => {
		return Object.values(cartState.products).find((product) => {
			return (
				product.size === size &&
				product.quantity === quantity &&
				product.product === productID &&
				product.embroidery === embroidery &&
				product.addLogo === addLogo
			);
		});
	}, [cartState.products, size, quantity, productID, embroidery, addLogo]);

	const [removing, setRemoving] = useState(false);
	const [sizes, setSizes] = useState([]);
	const [sizeScale, setSizeScale] = useState([]);
	const [selectedSize, setSelectedSize] = useState(size);
	const [selectedQuantity, setSelectedQuantity] = useState(quantity);
	const [selectedEmbroidery, setSelectedEmbroidery] = useState(embroidery);
	const [availableQuantity, setAvailableQuantity] = useState();

	const product = useMemo(() => products[productID], [products, productID]);
	// Calculate the product price with the logo option
  const calculatePrice = useMemo(() => {
    const product = products[productID];
    if (!product) return 0;
    let totalPrice;
		const shouldAddLogoPrice = addLogo && customUnits !== 'Units' && CLIENT === GTN;
    if (shouldAddLogoPrice) {
      totalPrice = (product.price + 5) * quantity; // Add $5 for the logo
    } else {
			totalPrice = product.price * quantity;
		}
    return totalPrice;
  }, [products, productID, quantity, addLogo]);

  // Render the product price
  const renderPrice = useCallback(() => {
    return <span className="productPrice">{getPriceString(calculatePrice, customUnits)}</span>;
  }, [calculatePrice, customUnits]);

	const productStyle = useMemo(
		() => getEncodedProductStyle(product),
		[product]
	);
	const productColor = useMemo(
		() => getEncodedProductColor(product),
		[product]
	);

	const monogram = useMemo(() => {
		return thisCartProduct?.embroidery?.find((option) => option.monogram)
			?.monogram;
	}, [thisCartProduct?.embroidery]);

	const [monogramTopText, setMonogramTopText] = useState(
		monogram?.topText || ""
	);
	const [monogramBottomText, setMonogramBottomText] = useState(
		monogram?.bottomText || ""
	);
	const [monogramStyle, setMonogramStyle] = useState(monogram?.style || "");

	const getAvailableQuantity = useCallback(async () => {
		if (!product || !selectedSize) return;
		if (sizeScale.length !== 0) {
			const nz = getNzForSizeInSizeScale(selectedSize, sizeScale);
			const qty = await getAvailability(product, nz, lastOrderDate);
			setAvailableQuantity(qty);
		}
	}, [product, selectedSize, setAvailableQuantity, sizeScale, lastOrderDate]);

	// Remove this product from the cart
	const remove = useCallback(async () => {
		setRemoving(true);
		try {
			cartDispatch({
				type: REMOVE_PRODUCT,
				cartProduct: {
					id,
					size,
					quantity,
					embroidery,
					price: product.price,
					product,
				},
				productList,
			});
		} catch (error) {
			console.log(`Error removing product: ${error}`);
			setRemoving(false);
		}
	}, [
		setRemoving,
		product,
		cartDispatch,
		id,
		size,
		quantity,
		productList,
		embroidery,
	]);

	// Set the cart product if anything changes
	useEffect(() => {
		if (!id || !selectedSize || !selectedQuantity) return;

		const embroidery = selectedEmbroidery.map((emb) =>
			isMonogram(emb.tape)
				? {
						...emb,
						monogram: {
							topText: monogramTopText,
							bottomText: monogramBottomText,
							style: monogramStyle,
						},
				  }
				: emb
		);

		cartDispatch({
			type: SET_CART_PRODUCT,
			id,
			cartProduct: {
				size: selectedSize,
				quantity: selectedQuantity,
				embroidery,
			},
		});
	}, [
		id,
		selectedSize,
		selectedQuantity,
		cartDispatch,
		selectedEmbroidery,
		isMonogram,
		monogramTopText,
		monogramBottomText,
		monogramStyle,
	]);

	// Set the size scale for this product
	useEffect(() => {
		if (!product || !selectedSize) return;
		getSizeScale(product.xID).then((sizes) => {
			// Only show available sizes
			asyncFilter(sizes, async (size) => {
				const nz = getNzForSizeInSizeScale(size, sizes);
				const availability = await getAvailability(product, nz, lastOrderDate);
				return availability > 0;
			}).then((available) => {
				setSizes(available);
				setSizeScale(sizes);
			});
		});
	}, [product, selectedSize, lastOrderDate]);

	// Set the quantity for the selected size
	useEffect(() => {
		getAvailableQuantity();
	}, [getAvailableQuantity]);

	// Dispatch to cart when selected quantity changes
	const changeQuantity = useCallback(
		async (event) => {
			const newQuantity = event.target.value;
			setSelectedQuantity(parseInt(newQuantity));
		},
		[setSelectedQuantity]
	);

	// Display size scale options for this product
	const renderSizeOptions = useCallback(() => {
		return sizes.map((s, i) => {
			return (
				<option key={i} value={s}>
					{s}
				</option>
			);
		});
	}, [sizes]);

	// Display quantity options for the size selection
	const renderQuantityOptions = useCallback(() => {
		if (availableQuantity === 0 && !removing && !!product) {
			return;
		}
		// There aren't enough left, warn the user
		if (selectedQuantity > availableQuantity) {
			alert(
				`Sorry, we don't have enough of ${product.styleNameLong} left in size ${selectedSize}. Your quantity has been reduced to our current availability.`
			);
			setSelectedQuantity(availableQuantity);
		}
		return Array.from({ length: availableQuantity }, (_, i) => i + 1).map(
			(q) => {
				return (
					<option value={q} key={q}>
						{q}
					</option>
				);
			}
		);
	}, [selectedQuantity, selectedSize, availableQuantity, removing, product]);

	const renderSizeSelector = useCallback(() => {
		return (
			<div className="sizeSelector">
				<label>Size</label>
				<select
					value={selectedSize}
					onChange={(e) => setSelectedSize(e.target.value)}
					className="sizeOptions"
				>
					{renderSizeOptions()}
				</select>
			</div>
		);
	}, [selectedSize, renderSizeOptions]);

	const renderQuantitySelector = useCallback(() => {
		return (
			<div className="quantitySelector">
				<label>Quantity</label>
				<select
					value={selectedQuantity}
					onChange={(e) => changeQuantity(e)}
					className="quantityOptions"
				>
					{renderQuantityOptions()}
				</select>
			</div>
		);
	}, [selectedQuantity, changeQuantity, renderQuantityOptions]);

	const [showMonogramConfig, setShowMonogramConfig] = useState(false);

	const toggleMonogramConfig = useCallback(() => {
		setShowMonogramConfig((prev) => !prev);
	}, [setShowMonogramConfig]);

	const renderMonogramToggler = useCallback(() => {
		const hasMonogramOption = selectedEmbroidery.some((emb) => isMonogram(emb.tape));

		if (!hasMonogramOption) return <> </>;
		return (
			<div className="editMonogram">
				<button onClick={toggleMonogramConfig} className="monogramConfig">
					Edit monogram
				</button>
			</div>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [toggleMonogramConfig, selectedEmbroidery, isMonogram]);

	const renderEmbroideryOptions = useCallback(
		() => (
			<div className="configLogo">
				<div style={{ width: "100%" }}>
					{product?.embroidery.map((option, index) => {
						return (
							<EmbroideryOption
								key={index}
								option={option}
								selectedEmbroidery={selectedEmbroidery}
								setSelectedEmbroidery={setSelectedEmbroidery}
								monogram={{
									topText: monogramTopText,
									bottomText: monogramBottomText,
									style: monogramStyle,
								}}
							/>
						);
					})}
				</div>
			</div>
		),
		[
			product?.embroidery,
			selectedEmbroidery,
			setSelectedEmbroidery,
			monogramTopText,
			monogramBottomText,
			monogramStyle,
		]
	);

	const renderRemoveButton = useCallback(() => {
		return (
			<div className="remover">
				<button onClick={() => remove()} className="removeItem">
					{removing ? "Removing From Cart..." : "Remove from cart"}
				</button>
			</div>
		);
	}, [remove, removing]);

	if (!product) return null;
	const style = product.styleNameLong
		? product.styleNameLong
		: product.styleCode;
	const color = product.colorNameLong
		? product.colorNameLong
		: product.colorCode;

	return (
		<div className="checkoutProduct greyBorder">
			<Link
				to={`/${eventID}/store/product/${productStyle}/${productColor}`}
				className="productImage productImage"
				style={{ backgroundImage: `url(${getBitmap(product.image)})` }}
			></Link>
			<div className="checkoutProductInfo">
				<div className="topLine">
					<span className="productName">{style}</span>
					<span className="productPrice">
						{renderPrice()}
					</span>
				</div>

				<span className="color">{color}</span>
				{renderSizeSelector()}
				{renderQuantitySelector()}

				{product.embroidery &&
					lastLogoDate?.toDate() > new Date() &&
					renderEmbroideryOptions()}
				<div>
  				{addLogo && <span style={{ color: 'blue' }}>Logo added</span>}
				</div>
				{renderRemoveButton()}
				{renderMonogramToggler()}

				{showMonogramConfig && (
					<MonogramConfig
						monogramTopText={monogramTopText}
						monogramBottomText={monogramBottomText}
						monogramStyle={monogramStyle}
						onCancel={() => setShowMonogramConfig(false)}
						onSave={({ topText, bottomText, fontStyle }) => {
							setShowMonogramConfig(false);
							setMonogramTopText(topText);
							setMonogramBottomText(bottomText);
							setMonogramStyle(fontStyle);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default CheckoutProduct;
