import React, { useState, useContext, useCallback } from "react";
import { Link } from "react-router-dom";
import { EventContext } from "../context/EventContext";
import { getTitleFromGenderCode } from "../helpers";

const NavTreeNode = ({ gender, selectedNode, setSelectedNode }) => {
  const [open, setOpen] = useState(true);
  const { eventID, categories } = useContext(EventContext);
  const title = gender ? getTitleFromGenderCode(gender) : "All";
  const category = gender ? title : "";

  const handleClick = useCallback(() => {
    if (selectedNode === category) setOpen(!open);
    setSelectedNode(category);
  }, [category, selectedNode, setSelectedNode, open, setOpen]);

  const showCategories = useCallback((subcategories) => {
    if (!subcategories) return;
    return (
      <ul ng-show="category.hasItems">
        {subcategories.map((subcategory, i) => {
          return (
            <li key={i} className="">
              <Link
                to={`/${eventID}/store/${category}/${subcategory}`}
                onClick={() => setSelectedNode(subcategory)}
                className={`nav3 ng-binding ${selectedNode === subcategory ? "selected" : ""
                  }`}
              >
                {subcategory}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }, [eventID, category, selectedNode, setSelectedNode]);

  return (
    <li className="treeview-node">
      <Link
        to={`/${eventID}/store/${category}`}
        className={`nav1 ${gender ? "expandable" : ""} ${open ? "expanded" : ""} ${selectedNode === category ? "selected" : ""
          }`}
        onClick={() =>
          handleClick()
        }
      >
        <span className="title ng-binding">{title}</span>
      </Link>
      <div className="treeview-node-content collapsible-vertical ng-hide">
        {open ? showCategories(categories[gender]) : null}
      </div>
    </li>
  );
};

export default NavTreeNode;
